// =====================
//   Copyright BOOJOB
//======================


//------手机验证 开始-------
$("#btn_MobileSave").on("click", function () {

    var Mobile = $("#txt_Mobile").val();
    var SMSCode = $("#txt_SMSCode").val();
    var Usertype = $("#hid_Usertype").val();

    $.ajax({
        url: "/public/ajax/getpassword.aspx?action=mobilevalid",
        type: "POST",
        data: { Mobile: Mobile, SMSCode: SMSCode, Usertype: Usertype },
        beforeSend: function () {
            $("#btn_MobileSave").html("正在提交...").attr("disabled", "disabled");
        },
        dataType: "json",
        success: function (result) {
            if (result[0].Flag) {
                $("#btn_MobileSave").html("验证成功");
                location.href = "/public/getpassword_three.aspx?usertype=" + Usertype;
            }
            else {
                $("#btn_MobileSave").html("下一步").removeAttr("disabled");
                layer.msg(result[0].Msg, { icon: 2 });
            }
        },
        error: function () {
            $("#btn_MobileSave").html("下一步").removeAttr("disabled");
            layer.msg("操作过程出错！", { icon: 2 });
        }
    });
});


//发送验证码到手机
var InterValObj; //timer变量，控制时间  
var count = 60; //间隔函数，1秒执行  
var curCount; //当前剩余秒数  
var code = ""; //验证码  
var codeLength = 6; //验证码长度 
var valmobile = "true";
var valcode = "true";

//点击开始
$("#toSendSMS").on("click", function () {
    var thisButton = $(this);
    var phone = $("#txt_Mobile").val(); //手机号码  
    var usertype = $("#hid_Usertype").val(); //会员类型  
    curCount = count;

    //手机验证
    if (phone != "") {
        if (checkMobile())
            $.ajax({
                type: "POST",
                dataType: "text",
                url: '/public/ajax/getpassword.aspx',
                data: { action: "mobilesend", value: phone, usertype: usertype },
                success: function (msg) {
                    valmobile = msg;
                    if (valmobile == "true") {
                        //弹出验证框
                        layer.open({
                            type: 1,
                            title: false,
                            closeBtn: true,
                            area: '302px',
                            shadeClose: true,
                            content: $('#ValidateDivs')
                        });
                        //滑动验证
                        $("#ValidateDiv").slide("300*200", "sendSMScode");
                    }
                    else {
                        layer.msg("该手机号码不存在", { icon: 2 });
                    }
                }
            });
        else {
            layer.msg("手机号格式不正确", { icon: 2 });
            valmobile = "false";
        }
    }
    else {
        layer.msg("请输入手机号码", { icon: 2 });
        valmobile = "false";
    }

});

//请求验证码
function sendSMScode(token) {
    var mobile = $("#txt_Mobile").val();
    layer.closeAll();

    if (token) {
        $.ajax({
            type: "POST",
            dataType: "text",
            url: '/register/person_chk.aspx',
            data: { action: "sendsmscode", value: mobile, smstype: "1", token: token },
            success: function (msg) {
                switch (msg) {
                    case "true":
                        outtime(); //启动计时
                        break;
                    case "num":
                        layer.msg("你今日已接收5条信息");
                        break;
                    case "black":
                        layer.msg("发送权限屏蔽中");
                        break;
                    case "flag":
                        layer.msg("短信平台屏蔽中");
                        break;
                    case "lost":
                        layer.msg("发送失败");
                        break;
                    case "token":
                        layer.msg("验证失败");
                        break;
                }
            }
        });
    }
    else {
        layer.msg("验证失败");
    }
}

var wait = 60; //计时器
function outtime() {
    if (wait == 0) {
        $("#toSendSMS").attr("disabled", false);
        $("#toSendSMS").val("获取验证码")
        wait = 60;
    } else {
        $("#toSendSMS").attr("disabled", true);
        $("#toSendSMS").val('' + wait + "S");
        wait--;
        setTimeout(function () {
            outtime()
        },
           1000)
    }
}

//------手机验证 结束-------


//------邮箱验证 开始-------
$("#btn_EmailSave").on("click", function () {

    var Email = $("#txt_Email").val();
    var EmailCode = $("#txt_EmailCode").val();
    var Usertype = $("#hid_Usertype").val();

    $.ajax({
        url: "/public/ajax/getpassword.aspx?action=emailvalid",
        type: "POST",
        data: { Email: Email, EmailCode: EmailCode, Usertype: Usertype },
        beforeSend: function () {
            $("#btn_EmailSave").html("正在提交...").attr("disabled", "disabled");
        },
        dataType: "json",
        success: function (result) {
            if (result[0].Flag) {
                $("#btn_EmailSave").html("验证成功");
                location.href = "/public/getpassword_three.aspx?usertype=" + Usertype;
            }
            else {
                $("#btn_EmailSave").html("下一步").removeAttr("disabled");
                layer.msg(result[0].Msg, { icon:2 });
            }
        },
        error: function () {
            $("#btn_EmailSave").html("下一步").removeAttr("disabled");
            layer.msg("操作过程出错！", { icon: 2 });
        }
    });
});

//发送验证码到邮箱
$("#toSendMail").on("click", function () {
    var thisButton = $(this);
    var phone = $("#txt_Email").val(); //邮箱地址 
    var usertype = $("#hid_Usertype").val(); //会员类型  
    curCount = count;

    //验证
    if (phone != "") {
        if (checkEmail())
            $.ajax({
                type: "POST",
                dataType: "text",
                url: '/public/ajax/getpassword.aspx',
                data: { action: "emailsend", value: phone, usertype: usertype },
                success: function (msg) {
                    valmobile = msg;
                    if (valmobile == "true") {
                        $("#toSendMail").attr("disabled", "true");
                        $("#toSendMail").val("已发送");
                        $.ajax({
                            type: "POST",
                            dataType: "text",
                            url: '/register/person_chk.aspx',
                            data: { action: "sendmail", value: phone },
                            success: function (msg) { }
                        });
                    }
                    else {
                        layer.msg("邮箱不存在", { icon: 2 });
                    }
                }
            });
        else {
            layer.msg("邮箱格式不正确", { icon: 2 });
            valmobile = "false";
        }
    }
    else {
        layer.msg("请输入邮箱地址");
        valmobile = "false";
    }

});
//------邮箱验证 结束------



//------设置新密码 开始------
$("#btn_PasswordSave").on("click", function () {

    var Password = $("#txt_Password").val();
    var Password2 = $("#txt_Password2").val();
    var Usertype = $("#hid_Usertype").val();

    if (Password == "") {
        layer.msg("请输入新密码", { icon: 2 });
        return false;
    }
    if (Password.length < 4 || Password.length > 20) {
        layer.msg("请输入4~20位字符密码", { icon: 2 });
        return false;
    }
    if (Password != Password2) {
        layer.msg("两次输入的密码不一致", { icon: 2 });
        return false;
    }

    $.ajax({
        url: "/public/ajax/getpassword.aspx?action=setpassword",
        type: "POST",
        data: { Password: Password, Usertype: Usertype },
        beforeSend: function () {
            $("#btn_PasswordSave").html("正在提交...").attr("disabled", "disabled");
        },
        dataType: "json",
        success: function (result) {
            if (result[0].Flag) {
                $("#btn_PasswordSave").html("设置成功");
                location.href = result[0].Msg;

            }
            else {
                $("#btn_PasswordSave").html("确认修改").removeAttr("disabled");
                layer.msg(result[0].Msg, { icon: 2 });
            }
        },
        error: function () {
            $("#btn_PasswordSave").html("确认修改").removeAttr("disabled");
            layer.msg("操作过程出错！", { icon: 2 });
        }
    });

});
//------设置新密码 结束------



//验证手机
function checkMobile() {
    var reg = /^\s*(1[3|4|5|6|7|8|9])\d{9}\s*$/;
    return reg.test($("#txt_Mobile").val());
}
//验证Email
function checkEmail() {
    var reg = /^([a-zA-Z0-9_-])+(\.([a-zA-Z0-9_-])+)*@([a-zA-Z0-9_-])+(\.([a-zA-Z0-9_-])+)+$/;
    return reg.test($("#txt_Email")[0].value);
}

/* IE支持placeholder */
if( !('placeholder' in document.createElement('input')) ){  
   
    $('input[placeholder],textarea[placeholder]').each(function(){   
      var that = $(this),   
      text= that.attr('placeholder');   
      if(that.val()===""){   
        that.val(text).addClass('placeholder');   
      }   
      that.focus(function(){   
        if(that.val()===text){   
          that.val("").removeClass('placeholder');   
        }   
      })   
      .blur(function(){   
        if(that.val()===""){   
          that.val(text).addClass('placeholder');   
        }   
      })   
      .closest('form').submit(function(){   
        if(that.val() === text){   
          that.val('');   
        }   
      });   
    });   
  }