var NewNum = $("#hid_NewNum").val();


//顶部下拉菜单
$(function () {
    $(".topWebList").hover(function () {
        $(this).find(".webMoredown").removeClass("fn-hide");
        $(this).find(".webMore").addClass("webMorecurrent");
    }, function () {
        $(this).find(".webMoredown").addClass("fn-hide");
        $(this).find(".webMore").removeClass("webMorecurrent");
    });
})
$(function () {
    $(".nav li").hover(function () {
        $(this).find(".hMoredown").removeClass("fn-hide");
        $(this).find(".hMore").addClass("hMorecurrent");
    }, function () {
        $(this).find(".hMoredown").addClass("fn-hide");
        $(this).find(".hMore").removeClass("hMorecurrent");
    });
})


//最新职位
// $.ajax({
//         url: "../public/ajax/index.aspx?action=NewJob&Subid=0",
//         type: "post",
//         datatype: "text",
//         success: function (returnValue) {
//             if (returnValue != "" && returnValue != null) {
//                 $("#bbx02").html(returnValue);
//             }
//         }
// })


//应届生职位
// $.ajax({
//         url: "../public/ajax/index.aspx?action=CampusJob&Subid=0",
//         type: "post",
//         datatype: "text",
//         success: function (returnValue) {
//             if (returnValue != "" && returnValue != null) {
//                 $("#bbx04").html(returnValue);
//             }
//         }
// })

// //兼职职位
// $.ajax({
//         url: "../public/ajax/index.aspx?action=Jianzhi&Subid=0",
//         type: "post",
//         datatype: "text",
//         success: function (returnValue) {
//             if (returnValue != "" && returnValue != null) {
//                 $("#bbx05").html(returnValue);
//             }
//         }
// })

//最新招聘
$("#bl06").hover(function () {
    $.ajax({ url: "../public/ajax/index.aspx?action=TradeCompany&Tradeid=6&Num="+ NewNum,
         type: "post",datatype: "text",success: function (returnValue) { if (returnValue != "" && returnValue != null) { $("#bbx06").html(returnValue); }}
    })
 }); 
 $("#bl07").hover(function () {
    $.ajax({ url: "../public/ajax/index.aspx?action=TradeCompany&Tradeid=7&Num="+ NewNum,
         type: "post",datatype: "text",success: function (returnValue) { if (returnValue != "" && returnValue != null) { $("#bbx07").html(returnValue); }}
    })
 }); 
 $("#bl08").hover(function () {
    $.ajax({ url: "../public/ajax/index.aspx?action=TradeCompany&Tradeid=8&Num="+ NewNum,
         type: "post",datatype: "text",success: function (returnValue) { if (returnValue != "" && returnValue != null) { $("#bbx08").html(returnValue); }}
    })
 }); 
 $("#bl09").hover(function () {
    $.ajax({ url: "../public/ajax/index.aspx?action=TradeCompany&Tradeid=9&Num="+ NewNum,
         type: "post",datatype: "text",success: function (returnValue) { if (returnValue != "" && returnValue != null) { $("#bbx09").html(returnValue); }}
    })
 }); 
 $("#bl010").hover(function () {
    $.ajax({ url: "../public/ajax/index.aspx?action=TradeCompany&Tradeid=10&Num="+ NewNum,
         type: "post",datatype: "text",success: function (returnValue) { if (returnValue != "" && returnValue != null) { $("#bbx010").html(returnValue); }}
    })
 }); 
 $("#bl011").hover(function () {
    $.ajax({ url: "../public/ajax/index.aspx?action=TradeCompany&Tradeid=11&Num="+ NewNum,
         type: "post",datatype: "text",success: function (returnValue) { if (returnValue != "" && returnValue != null) { $("#bbx011").html(returnValue); }}
    })
 }); 

 

//照片人才
 $.ajax({ url: "../public/ajax/resume.aspx?action=PhoContent",
        type: "post",datatype: "text",success: function (returnValue) { if (returnValue != "" && returnValue != null) { $("#main7c").html(returnValue); }}
   })

//最新简历
 $.ajax({ url: "",
        type: "",datatype: "text",success: function (returnValue) { if (returnValue != "" && returnValue != null) { $("#resContent").html(returnValue); }}
   })

//求职登录
$("#per_Login").click(function () {

    var Username = $("#txt_Username").val();
    var Password = $("#txt_Password").val();

    if (Username == "" || Password == "") {
        alert('请输入用户名和密码！');
        return false;
    }
});
//企业登录
$("#com_Login").click(function () {

    var Username = $("#txt_comUsername").val();
    var Password = $("#txt_comPassword").val();

    if (Username == "" || Password == "") {
        alert('请输入用户名和密码！');
        return false;
    }
});

//微信登录
// function loginBoard(n) 
// { 
//     for(i=1;i<3;i++) 
//     { 
//         eval("document.getElementById('abx0"+i+"').style.display='none'"); 
//     }
//     eval("document.getElementById('abx0"+n+"').style.display='block'"); 
// }


//按钮响应
document.onkeydown = function (event) {
    var e = event || window.event || arguments.callee.caller.arguments[0];
    if (e && e.keyCode == 13) { // enter 键
        if (document.activeElement.id == "txt_Key") {
            document.getElementById('imgSearch').click();
        }
        if (document.activeElement.id == "txt_Username" || document.activeElement.id == "txt_Password") {
            document.getElementById('per_Login').click();
            return false;
        }
        if (document.activeElement.id == "txt_comUsername" || document.activeElement.id == "txt_comPassword") {
            document.getElementById('com_Login').click();
            return false;
        }
    }
};


//图片广告
$("ul.adul592").find("li").bind("mouseover", function () {
    $(this).find(".ad_li2").show();
    $("ul.adul592").find(".On").removeClass("On");
    $(this).addClass("On");
    $(this).css({ "z-index": "104" });
}).bind("mouseout", function () {
    $(this).find(".ad_li2").hide();
    $(this).removeClass("On");
    $(this).css({ "z-index": "1" });
})

$("ul.adul391").find("li").bind("mouseover", function () {
    $(this).find(".ad_li2").show();
    $("ul.adul391").find(".On").removeClass("On");
    $(this).addClass("On");
    $(this).css({ "z-index": "104" });
}).bind("mouseout", function () {
    $(this).find(".ad_li2").hide();
    $(this).removeClass("On");
    $(this).css({ "z-index": "1" });
})

$("ul.adul290").find("li").bind("mouseover", function () {
    $(this).find(".ad_li2").show();
    $("ul.adul290").find(".On").removeClass("On");
    $(this).addClass("On");
    $(this).css({ "z-index": "104" });
}).bind("mouseout", function () {
    $(this).find(".ad_li2").hide();
    $(this).removeClass("On");
    $(this).css({ "z-index": "1" });
})

$("ul.adul189").find("li").bind("mouseover", function () {
    $(this).find(".ad_li2").show();
    $("ul.adul189").find(".On").removeClass("On");
    $(this).addClass("On");
    $(this).css({ "z-index": "104" });
}).bind("mouseout", function () {
    $(this).find(".ad_li2").hide();
    $(this).removeClass("On");
    $(this).css({ "z-index": "1" });
})


//知名企业
$("div.main3c").find("ul").bind("mouseover", function () {
    $(this).find(".brandPop").show();
    $("div.main3c").find(".On").removeClass("On");
    $(this).addClass("On");
    $(this).css({ "z-index": "104" });
}).bind("mouseout", function () {
    $(this).find(".brandPop").hide();
    $(this).removeClass("On");
    $(this).css({ "z-index": "1" });
})

//照片简历
$("ul.ulphoto").find("li").bind("mouseover", function () {
    $(this).find(".ad_li2").show();
    $("ul.ulphoto").find(".On").removeClass("On");
    $(this).addClass("On");
    $(this).css({ "z-index": "104" });
}).bind("mouseout", function () {
    $(this).find(".ad_li2").hide();
    $(this).removeClass("On");
    $(this).css({ "z-index": "1" });
})


//顶部搜索
function Search_Header() {
    var Url;
    var KeyClass, Key;

    Key = document.getElementById("txt_HeadKey").value;
    KeyClass = document.getElementById("KeyClass").value;

    if (Key == "请输入您要找的公司或职位关键字") { Key = ""; }
    if (Key == "请输入简历关键字") { Key = ""; }

    if (KeyClass == "2")
        Url = "jianli/search.aspx?Key=" + Key;
    else
        Url = "job/?Key=" + Key;

    window.open(Url);
}


//安装到桌面
function toDesktop(sUrl, sName) {
    try {
        var WshShell = new ActiveXObject("WScript.Shell");
        var oUrlLink = WshShell.CreateShortcut(WshShell.SpecialFolders("Desktop") + "\\" + sName + ".url");
        oUrlLink.TargetPath = sUrl;
        oUrlLink.Save();
    }
    catch (e) {
        alert("当前浏览器安全级别不允许操作！");
    }
}

//底部图层
var isie6 = window.XMLHttpRequest ? false : true;
window.onscroll = function () {
    var t = document.documentElement.scrollTop || document.body.scrollTop;
    if (t >= 10) {
        var a = document.getElementById('a');
        var d = document.getElementById('d');
        if (isie6) {
            a.style.position = 'absolute';

            window.onscroll = function () {
                d.innerHTML = '';
            }
        } else {
            a.style.position = 'fixed';
        }
        a.style.right = '0';
        a.style.bottom = '0';
    }
}
window.footclose = function () {
    $("#a").hide();
}



window.asecBoard = function (n) {
    for (i = 1; i < 3; i++) {
        eval("document.getElementById('al0" + i + "').className='a102'");
        eval("document.getElementById('abx0" + i + "').style.display='none'");
    }
    eval("document.getElementById('al0" + n + "').className='a101'");
    eval("document.getElementById('abx0" + n + "').style.display='block'");
    eval("document.getElementById('abx03').style.display='none'");
}

window.bsecBoard = function (n) {
    for (i = 1; i < 6; i++) {
        eval("document.getElementById('bl0" + i + "').className='b102'");
        eval("document.getElementById('bbx0" + i + "').style.display='none'");
    }
    eval("document.getElementById('bl0" + n + "').className='b101'");
    eval("document.getElementById('bbx0" + n + "').style.display='block'");
}

window.csecBoard = function (n) {
    for (i = 1; i < 3; i++) {
        eval("document.getElementById('cl0" + i + "').className='c102'");
        eval("document.getElementById('cbx0" + i + "').style.display='none'");
    }
    eval("document.getElementById('cl0" + n + "').className='c101'");
    eval("document.getElementById('cbx0" + n + "').style.display='block'");
}

window.signBoard = function (n) {
    for (i = 1; i < 3; i++) {
        eval("document.getElementById('el0" + i + "').className='e102'");
        eval("document.getElementById('ebx0" + i + "').style.display='none'");
    }
    eval("document.getElementById('el0" + n + "').className='e101'");
    eval("document.getElementById('ebx0" + n + "').style.display='block'");
}
window.dsecBoard = function (n) {
    for (i = 1; i < 5; i++) {
        eval("document.getElementById('dl0" + i + "').className='d102'");
        eval("document.getElementById('dbx0" + i + "').style.display='none'");
    }
    eval("document.getElementById('dl0" + n + "').className='d101'");
    eval("document.getElementById('dbx0" + n + "').style.display='block'");
}












