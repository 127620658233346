// ==========================
//     Copyright BOOJOB
//===========================




$(function () {
    $(".hTopleft li").hover(function () {
        $(this).find(".hMoredown").removeClass("fn-hide");
        $(this).find(".hMore").addClass("hMorecurrent");
    }, function () {
        $(this).find(".hMoredown").addClass("fn-hide");
        $(this).find(".hMore").removeClass("hMorecurrent");
    });
})
$(function () {
    $(".hTopLogin").hover(function () {
        $(this).find(".lMoredown").removeClass("fn-hide");
        $(this).find(".lMore").addClass("lMorecurrent");
    }, function () {
        $(this).find(".lMoredown").addClass("fn-hide");
        $(this).find(".lMore").removeClass("lMorecurrent");
    });
})

$(function () {
    $("#share .s2").hover(function () {
        $(this).find(".shareMoredown").removeClass("fn-hide");
        $(this).find(".shareMore").addClass("shareMorecurrent");
    }, function () {
        $(this).find(".shareMoredown").addClass("fn-hide");
        $(this).find(".shareMore").removeClass("shareMorecurrent");
    });
})