//轮播图
; (function () {
    $('#js_ban_button_box').on('click', 'a', function () {
        var btn = $(this);
        if (btn.hasClass('right')) {
            //next
            next(function () {
                start();
            });
        }
        else {
            //prev
            var newInd = defaultInd - 1;
            if (newInd < 0) {
                newInd = list.length - 1;
            }
            change(newInd, function () {
                start();
            });
        }
        return false;
    });

})();


